// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-base-js": () => import("./../../../src/pages/base.js" /* webpackChunkName: "component---src-pages-base-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-grow-js": () => import("./../../../src/pages/grow.js" /* webpackChunkName: "component---src-pages-grow-js" */),
  "component---src-pages-how-to-get-online-js": () => import("./../../../src/pages/how-to-get-online.js" /* webpackChunkName: "component---src-pages-how-to-get-online-js" */),
  "component---src-pages-impress-js": () => import("./../../../src/pages/impress.js" /* webpackChunkName: "component---src-pages-impress-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-why-have-a-website-js": () => import("./../../../src/pages/why-have-a-website.js" /* webpackChunkName: "component---src-pages-why-have-a-website-js" */)
}

